// src/version.js
export const versions = [
  { version: "0.2.3 preview", date: "2024-09-15", notes: ["Opravena chyba, kdy se počítá cena i za kategorii v soutěži i když je to nežádoucí (předělání nastavení kategorií).", "V kalendáři se nyní zobrazují i informace o etapách."] },
  { version: "0.2.2", date: "2024-09-09", notes: ["Start 00 pro etapy, startovní čas a cílový čas závodníka nejsou nyní převáděny podle časového pásma", "V sekci Členové je nyní k dispozici základní filtrace. Další přibude po integraci trenérů, rozhodčích a VT", "V oddílové sekci bylo kompletně předěláno zobrazení podle preferencí vedoucích oddílů", "Oprava chyb a menší úpravy textu"] },
  { version: "0.2.1", date: "2024-09-07", notes: ["Opravena chyba, kdy se na závod nemohlo přihlásit více lidí jednorázovou přihláškou", "Přidáno zobrazení počtu přihlášených na závod a počet členů v oddíle"] },
  { version: "0.2.0", date: "2024-08-23", notes: ["Integrace startovek", "Stránka se všemy Exporty, které robis aktuálně nabízí"] },
  { version: "0.1.1", date: "2024-08-12", notes: ["Email při registraci již není povinný, viz nápověda", "Opraveny drobné chyby"] },
  { version: "0.1.0", date: "2024-08-09", notes: ["Spuštění beta verze"] },
];

export const latestVersion = versions[0].version;
